import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    createProduct,
    getProduct,
    updateProduct,
    listIngredients,
} from "../../api/OtterApi";
import { useAuth0 } from "@auth0/auth0-react";
import WizardPage from "../wizard/WizardPage";
import WizardCard from "../wizard/WizardCard";
import WizardTextField from "../wizard/WizardTextField";
import WizardTextView from "../wizard/WizardTextView";
import { Divider } from "semantic-ui-react";
import WizardFloatField from "../wizard/WizardFloatField";
import WizardDropDown from "../wizard/WizardDropDown";
import { SUPPORTED_UNITS_OPTIONS } from "../general/Constant";

const CreateProductPage = ({ match }) => {
    const { getAccessTokenSilently } = useAuth0();
    const history = useHistory();
    const [productId, setProductId] = useState(match.params.id);
    const [product, setProduct] = useState({});
    const [data, setData] = useState({
        name: "",
        description: "",
        ingredient: undefined,
        brand: "",
        quantity: "",
        units: "",
        calories: "",
        carbohydrates: "",
        fats: "",
        proteins: "",
    });
    const [ingredientOptions, setIngredientOptions] = useState([]);
    const [ingredientQuery, setIngredientQuery] = useState();

    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (product) {
            setData({
                name: product?.name || "",
                description: product?.description || "",
                ingredient: { ingredient_id: product?.ingredient_id },
                brand: product?.brand || "",
                quantity: product?.serving_size || "",
                units: product?.units || "",
                calories:
                    typeof product?.nutrition?.calories == "number"
                        ? product?.nutrition?.calories
                        : "",
                carbohydrates:
                    typeof product?.nutrition?.carbohydrates == "number"
                        ? product?.nutrition?.carbohydrates
                        : "",
                fats:
                    typeof product?.nutrition?.fats == "number"
                        ? product?.nutrition?.fats
                        : "",
                proteins:
                    typeof product?.nutrition?.proteins == "number"
                        ? product?.nutrition?.proteins
                        : "",
            });
        }
    }, [product]);

    useEffect(() => {
        const instantiateProduct = async () => {
            const token = await getAccessTokenSilently();
            const response = await getProduct(token, productId);
            if (response.status === 200) {
                setProduct(response.data.product);
            }
        };

        if (productId) {
            instantiateProduct();
        }
    }, []);

    useEffect(() => {
        const getIngredients = async () => {
            const token = await getAccessTokenSilently();
            const response = await listIngredients(
                token,
                10,
                0,
                ingredientQuery
            );
            if (response.status === 200) {
                setIngredientOptions(
                    getIngredientDropdownOptions(response.data.ingredients)
                );
            }
        };
        getIngredients();
    }, [getAccessTokenSilently, ingredientQuery]);

    const getIngredientDropdownOptions = (ingredients) => {
        return ingredients.map((ingredient) => {
            return {
                key: ingredient.id,
                value: ingredient,
                text: ingredient.name,
            };
        });
    };

    const createProductResource = async (token, product) => {
        const createProductResponse = await createProduct(token, product);

        if (createProductResponse.status === 201) {
            const productId = createProductResponse.data.product.id;

            history.push("/product/" + productId);
        } else {
            setErrorMessage(createProductResponse.data.error);
        }
    };

    const editProductResource = async (token, product) => {
        const editProductResponse = await updateProduct(token, product);

        if (editProductResponse.status === 200) {
            const productId = editProductResponse.data.product.id;

            history.push("/product/" + productId);
        } else {
            setErrorMessage(editProductResponse.data.error);
        }
    };

    const saveProduct = async () => {
        if (saving) return;
        setSaving(true);
        const token = await getAccessTokenSilently();
        const productPayload = {
            id: product?.id || "",
            name: data.name,
            description: data.description,
            ingredient_id: data.ingredient.id,
            serving_size: parseFloat(data.quantity),
            units: data.units,
            brand: data.brand,
            nutrition: {
                calories: parseFloat(data.calories),
                carbohydrates: parseFloat(data.carbohydrates),
                fats: parseFloat(data.fats),
                proteins: parseFloat(data.proteins),
            },
        };

        if (productId) {
            editProductResource(token, productPayload);
        } else {
            createProductResource(token, productPayload);
        }

        setSaving(false);
    };

    const getPageTitle = () => {
        return productId ? "Edit Product" : "Create Product";
    };

    return (
        <WizardPage title={getPageTitle()} data={data} setData={setData}>
            <WizardCard
                title="Details"
                setData={setData}
                hasRequirements={data?.name?.trim() && data?.brand?.trim()}
            >
                <WizardTextField
                    title="Name"
                    placeholder="Unsweetened Soy Milk"
                    value={data.name}
                    onChange={(e) => {
                        const eventValue = e.target.value;
                        const newName =
                            eventValue.charAt(0).toUpperCase() +
                            eventValue.slice(1);
                        setData({ ...data, name: newName });
                    }}
                    required={true}
                    max={100}
                />
                <WizardTextField
                    title="Description"
                    placeholder="Plant based dairy alternative"
                    value={data.description}
                    required={false}
                    onChange={(e) => {
                        setData({
                            ...data,
                            description: e.target.value,
                        });
                    }}
                />
                <WizardTextField
                    title="Brand"
                    placeholder="Silk"
                    value={data.brand}
                    required={true}
                    onChange={(e) => {
                        setData({
                            ...data,
                            brand: e.target.value,
                        });
                    }}
                />
            </WizardCard>
            <WizardCard
                title="Select Ingredient"
                errorMessage={errorMessage}
                dialog={
                    "Select an ingredient that represents the product you are creating. This allows us to recommended this product in place of other products of the same ingredient type."
                }
            >
                {/* <div>
                    <h4>Select Ingredient Type</h4>
                </div> */}
                <WizardDropDown
                    title="Ingredient Type"
                    placeholder="Select Ingredient"
                    required={true}
                    options={ingredientOptions}
                    setSearchQuery={setIngredientQuery}
                    setValue={(value) => {
                        setData({ ...data, ingredient: value });
                    }}
                    value={data.ingredient}
                    hasRequirements={data.ingredient}
                />
            </WizardCard>
            <WizardCard
                title="Add Nutritional Information"
                setData={setData}
                errorMessage={errorMessage}
                hasRequirements={
                    data?.units?.trim() &&
                    data.quantity &&
                    data.calories >= 0 &&
                    data.carbohydrates >= 0 &&
                    data.fats >= 0 &&
                    data.proteins >= 0
                }
                shouldSkip={false}
            >
                <div>
                    <h4>Serving Details</h4>
                </div>
                <WizardFloatField
                    title="Quantity"
                    placeholder="2"
                    value={data.quantity}
                    required={true}
                    onChange={(e) => {
                        setData({
                            ...data,
                            quantity: e.target.value,
                        });
                    }}
                />
                {/* <WizardTextField
                    title="Units"
                    placeholder="tbsp"
                    value={data.units}
                    required={true}
                    onChange={(e) => {
                        setData({
                            ...data,
                            units: e.target.value,
                        });
                    }}
                /> */}
                <WizardDropDown
                    title="Units"
                    placeholder="Tablespoons"
                    required={true}
                    options={SUPPORTED_UNITS_OPTIONS}
                    setSearchQuery={(filterValue) => {
                        return SUPPORTED_UNITS_OPTIONS.filter((option) =>
                            option.value
                                .toLowerCase()
                                .includes(filterValue.toLowerCase())
                        );
                    }}
                    setValue={(value) => {
                        setData({
                            ...data,
                            units: value,
                        });
                    }}
                    value={data.units}
                    // hasRequirements={data.ingredient}
                />
                <Divider />
                <div>
                    <h4>Macronutrients Per Serving</h4>
                </div>
                <WizardFloatField
                    title="Calories"
                    placeholder="150"
                    value={data.calories}
                    required={true}
                    onChange={(e) => {
                        setData({
                            ...data,
                            calories: e.target.value,
                        });
                    }}
                    hasRequirements={(value) => {
                        return value >= 0;
                    }}
                />
                <WizardFloatField
                    title="Fats (g)"
                    placeholder="12"
                    value={data.fats}
                    required={true}
                    onChange={(e) => {
                        setData({
                            ...data,
                            fats: e.target.value,
                        });
                    }}
                    hasRequirements={(value) => {
                        return value >= 0;
                    }}
                />
                <WizardFloatField
                    title="Carbs (g)"
                    placeholder="20"
                    value={data.carbohydrates}
                    required={true}
                    onChange={(e) => {
                        setData({
                            ...data,
                            carbohydrates: e.target.value,
                        });
                    }}
                    hasRequirements={(value) => {
                        return value >= 0;
                    }}
                />
                <WizardFloatField
                    title="Proteins (g)"
                    placeholder="5"
                    value={data.proteins}
                    required={true}
                    onChange={(e) => {
                        setData({
                            ...data,
                            proteins: e.target.value,
                        });
                    }}
                    hasRequirements={(value) => {
                        return value >= 0;
                    }}
                />
            </WizardCard>
            <WizardCard
                title="Confirm Product"
                finish={saveProduct}
                errorMessage={errorMessage}
            >
                <div>
                    <h4>Details</h4>
                </div>
                <WizardTextView title="Name" value={data.name} />
                <WizardTextView
                    title="Description"
                    value={data?.description || "N/A"}
                />
                <WizardTextView title="Brand" value={data?.brand || "N/A"} />
                <Divider />
                <div>
                    <h4>Ingredient Type</h4>
                </div>
                <WizardTextView
                    title="Ingredient"
                    value={data?.ingredient?.name || "N/A"}
                />
                <Divider />
                <div>
                    <h4>Nutritional Information</h4>
                </div>
                <WizardTextView
                    title={"Serving Size"}
                    value={`${data?.quantity} ${data?.units}`}
                />
                <WizardTextView title={"Calories"} value={data?.calories} />
                <WizardTextView title={"Carbs"} value={data?.carbohydrates} />
                <WizardTextView title={"Fats"} value={data?.fats} />
                <WizardTextView title={"Proteins"} value={data?.proteins} />
            </WizardCard>
        </WizardPage>
    );
};

export default CreateProductPage;
