import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import {
  getCurrentMealPlan,
  getMeal,
  getMealPreference,
  searchMealPlanPreferences,
} from "../../../api/OtterApi";

const getTodaysMeals = (mealPlanPreference) => {
  const planned_meals = mealPlanPreference?.plannedmeal_preferences || [];

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const today = new Date();
  let day = weekday[today.getDay()];
  const todaysMeals = planned_meals.filter((planned_meal) => {
    return planned_meal?.day === day;
  });

  return todaysMeals;
};

const getNextMeal = (plannedMeals) => {
  if (!plannedMeals) return null;

  const now = new Date();
  const futurePlannedMeals = plannedMeals.filter((plannedMeal) => {
    const [hour, minute] = plannedMeal.time.split(":");
    const mealDate = new Date();
    mealDate.setHours(hour);
    mealDate.setMinutes(minute);
    return now < mealDate; // TODO: May need to be within 10 minutes give or take?
  });

  if (futurePlannedMeals?.length) {
    return futurePlannedMeals[0];
  }

  return null;
};

const WidgetStates = {
  LOADING: 0,
  NO_MEALPLAN: 1,
  ERROR: 3,
  LOADED: 4,
  NO_MORE_MEALS: 5,
};

const NextMealWidget = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [currentMealplanId, setCurrentMealplanId] = useState();
  const [mealPlanPreference, setMealPlanPreference] = useState({});
  const [nextMealPreference, setNextMealPreference] = useState();
  const [nextMeal, setNextMeal] = useState();
  const [time, setTime] = useState();
  const [state, setState] = useState(WidgetStates.LOADING);

  useEffect(() => {
    const initializeCurrentMealPlanID = async () => {
      const token = await getAccessTokenSilently();
      const getCurrentMealPlanResponse = await getCurrentMealPlan(token);
      if (getCurrentMealPlanResponse.status === 200) {
        setCurrentMealplanId(getCurrentMealPlanResponse.data.mealplan_id);
      } else {
        console.error(getCurrentMealPlanResponse.data.error);
        setCurrentMealplanId("N/A");
        setState(WidgetStates.NO_MEALPLAN);
      }
    };
    initializeCurrentMealPlanID();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const initializeMealPlan = async () => {
      const token = await getAccessTokenSilently();
      const response = await searchMealPlanPreferences(
        token,
        10,
        0,
        "",
        currentMealplanId
      );
      if (response.status === 200) {
        const preferences = response.data.mealplanpreferences || [];
        const default_preference = preferences.filter((preference) => {
          return preference.name.toLowerCase() === "default";
        })[0];
        setMealPlanPreference(default_preference);
      } else {
        console.error(response.data.error);
        setState(WidgetStates.NO_MEALPLAN);
      }
    };
    if (currentMealplanId) {
      initializeMealPlan();
    }
  }, [currentMealplanId, getAccessTokenSilently]);

  useEffect(() => {
    const instantiateNextMeal = async () => {
      const token = await getAccessTokenSilently();

      const meals = getTodaysMeals(mealPlanPreference);
      const nextMealPref = getNextMeal(meals);

      if (nextMealPref) {
        setTime(nextMealPref?.time);
        const response = await getMealPreference(
          token,
          nextMealPref?.mealpreference_id
        );
        if (response.status === 200) {
          setNextMealPreference(response.data.mealpreference);
        } else {
          console.error(response.data.error);
          setState(WidgetStates.NO_MEALPLAN);
        }
      } else {
        setState(WidgetStates.NO_MORE_MEALS);
      }
    };

    if (mealPlanPreference) {
      instantiateNextMeal();
    }
  }, [mealPlanPreference]);

  useEffect(() => {
    const instantiateNextMeal = async () => {
      const token = await getAccessTokenSilently();
      const response = await getMeal(token, nextMealPreference.meal_id);
      if (response.status === 200) {
        setNextMeal(response.data.meal);
        setState(WidgetStates.LOADED);
      } else {
        console.error(response.data.error);
        setState(WidgetStates.NO_MEALPLAN);
      }
    };

    if (nextMealPreference) {
      instantiateNextMeal();
    }
  }, [nextMealPreference]);

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  if (state === WidgetStates.LOADING) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "white",
          borderRadius: "5px",
          boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.3)",
          animation: "fadeIn 1s",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Loader active inline="centered" size="tiny" />
        </div>
      </div>
    );
  } else if (state === WidgetStates.NO_MEALPLAN) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "white",
          borderRadius: "5px",
          boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.3)",
          animation: "fadeIn 1s",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div>
            <h4 style={{ margin: "auto", height: "100%", width: "70%" }}>
              No favorite Meal Plan set.
            </h4>
          </div>
        </div>
      </div>
    );
  } else if (state === WidgetStates.NO_MORE_MEALS) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "white",
          borderRadius: "5px",
          boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.3)",
          animation: "fadeIn 1s",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div>
            <h4 style={{ margin: "auto", height: "100%", width: "70%" }}>
              You've finished your meals for the day.
            </h4>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "white",
        borderRadius: "5px",
        boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.3)",
        animation: "fadeIn 1s",
      }}
    >
      <div
        style={{
          height: "20%",
        }}
      >
        <h3
          style={{
            textAlign: "left",
            paddingLeft: "10px",
            paddingTop: "8px",
            height: "100%",
            fontSize: "small",
            fontFamily: "Futura,Trebuchet MS,Arial,sans-serif",
            fontWeight: "lighter",
            color: "grey",
          }}
        >
          Next Meal
        </h3>
      </div>
      <div
        style={{
          width: "100%",
          height: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div>
          {nextMeal?.id && (
            <Link
              to={`/meal/${nextMeal?.id}`}
              onMouseDown={stopPropagation}
              onTouchStart={stopPropagation}
            >
              <h4 style={{ margin: "auto", height: "100%", width: "70%" }}>
                {nextMeal?.name}
              </h4>
            </Link>
          )}
        </div>
        {time && (
          <span style={{ marginTop: "5px", color: "grey" }}>@ {time}</span>
        )}
        {currentMealplanId == "N/A" && <div></div>}
      </div>
    </div>
  );
};

export default NextMealWidget;
