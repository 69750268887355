import axios from "axios";

const domain = window.location.href;

let OTTER_API_BASE_URL = "http://localhost:8080";
if (domain.includes("beta.lifebespoked")) {
  OTTER_API_BASE_URL = "https://api-beta.lifebespoked.com";
} else if (domain.includes("www.lifebespoked")) {
  OTTER_API_BASE_URL = "https://api-prod.lifebespoked.com";
}

console.log("Hitting backend url: " + OTTER_API_BASE_URL);

const axiosInstance = axios.create({
  validateStatus: function (status) {
    return status >= 200 && status < 500;
  },
});

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const getShoppingListItem = async (
  token,
  ingredientId,
  unitType,
  unit,
  multiplier
) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: "/shoppinglist/ingredient/" + ingredientId,
    headers: { Authorization: `Bearer ${token}` },
    params: {
      unitType,
      unit,
      multiplier,
    },
  };
  return await axiosInstance(options);
};

export const updateShoppingListItemInventory = async (
  token,
  ingredientId,
  unitType,
  unit,
  quantity,
  deltaUnit,
  completed
) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "patch",
    url: "/shoppinglist/ingredient/" + ingredientId + "/inventory",
    headers: { Authorization: `Bearer ${token}` },
    params: {
      unit: deltaUnit,
      unitType,
    },
    data: { unit, quantity, completed },
  };
  return await axiosInstance(options);
};

export const createIngredient = async (token, ingredient) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "post",
    url: "/ingredient",
    headers: { Authorization: `Bearer ${token}` },
    data: ingredient,
  };
  return await axiosInstance(options);
};

export const deleteIngredient = async (token, id) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "delete",
    url: "/ingredient/" + id,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const getIngredient = async (token, id) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: "/ingredient/" + id,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const listIngredients = async (
  token,
  limit = 10,
  offset = 0,
  searchText
) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: "/ingredient",
    headers: { Authorization: `Bearer ${token}` },
    params: {
      limit,
      offset,
      searchText,
    },
  };
  return await axiosInstance(options);
};

export const updateIngredient = async (token, data) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "patch",
    url: "/ingredient",
    headers: { Authorization: `Bearer ${token}` },
    data,
  };
  return await axiosInstance(options);
};

export const createMeal = async (token, data) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "post",
    url: "/meal",
    headers: { Authorization: `Bearer ${token}` },
    data,
  };
  return await axiosInstance(options);
};

export const deleteMeal = async (token, id) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "delete",
    url: "/meal/" + id,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const getMeal = async (token, id) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: "/meal/" + id,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const listMeals = async (token, limit = 10, offset = 0, searchText) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: "/meal",
    headers: { Authorization: `Bearer ${token}` },
    params: {
      limit,
      offset,
      searchText,
    },
  };
  return await axiosInstance(options);
};

export const updateMeal = async (token, data) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "patch",
    url: "/meal",
    headers: { Authorization: `Bearer ${token}` },
    data,
  };
  return await axiosInstance(options);
};

export const addBundleToMeal = async (token, mealId, bundle) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "post",
    url: `bundle/meal/${mealId}`,
    headers: { Authorization: `Bearer ${token}` },
    data: bundle,
  };
  return await axiosInstance(options);
};

export const deleteBundleFromMeal = async (token, mealId, bundleId) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "delete",
    url: `/bundle/${bundleId}/meal/${mealId}`,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const createMealPlan = async (token, data, templateId) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "post",
    url: "/mealplan",
    headers: { Authorization: `Bearer ${token}` },
    data,
    params: {
      template_id: templateId,
    },
  };
  return await axiosInstance(options);
};

export const deleteMealPlan = async (token, id) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "delete",
    url: "/mealplan/" + id,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const getMealPlan = async (token, id) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: "/mealplan/" + id,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const listMealPlans = async (token, limit, offset, searchText) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: "/mealplan",
    headers: { Authorization: `Bearer ${token}` },
    params: {
      limit,
      offset,
      searchText,
    },
  };
  return await axiosInstance(options);
};

export const updateMealPlan = async (token, data) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "patch",
    url: "/mealplan",
    headers: { Authorization: `Bearer ${token}` },
    data,
  };
  return await axiosInstance(options);
};

export const addPlannedMealPreferenceBatch = async (
  token,
  mealPlanPreferenceId,
  plannedMealPrefs
) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "post",
    url: `/plannedmealpreference/mealplanpreference/${mealPlanPreferenceId}/batch`,
    headers: { Authorization: `Bearer ${token}` },
    data: plannedMealPrefs,
  };
  return await axiosInstance(options);
};

export const deletePlannedMeal = async (token, mealplanId, plannedmealId) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "delete",
    url: `plannedmeal/${plannedmealId}/mealplan/${mealplanId}`,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const getCurrentMealPlan = async (token) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: "/user/current/mealplan",
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const setCurrentMealPlan = async (token, mealplanId) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "patch",
    url: "/user/current/mealplan?id=" + mealplanId,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const listProducts = async (
  token,
  limit = 10,
  offset = 0,
  searchText
) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: "/product",
    headers: { Authorization: `Bearer ${token}` },
    params: {
      limit,
      offset,
      searchText,
    },
  };
  return await axiosInstance(options);
};

export const updateMealPreference = async (token, data) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "patch",
    url: "/mealpreference",
    headers: { Authorization: `Bearer ${token}` },
    data,
  };
  return await axiosInstance(options);
};

export const createMealPreference = async (token, data) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "post",
    url: "/mealpreference",
    headers: { Authorization: `Bearer ${token}` },
    data,
  };
  return await axiosInstance(options);
};

export const deleteMealPreference = async (token, id) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "delete",
    url: `/mealpreference/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const updateMealPlanPreference = async (token, data) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "patch",
    url: "/mealplanpreference",
    headers: { Authorization: `Bearer ${token}` },
    data,
  };
  return await axiosInstance(options);
};

export const createMealPlanPreference = async (token, data) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "post",
    url: "/mealplanpreference",
    headers: { Authorization: `Bearer ${token}` },
    data,
  };
  return await axiosInstance(options);
};

export const getMealPlanPreference = async (token, id, ingredients = false) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: `/mealplanpreference/${id}${ingredients ? "?ingredients=true" : ""}`,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const getMealPlanPreferenceIngredients = async (token, id) => {
  return await getMealPlanPreference(token, id, true);
};

export const deleteMealPlanPreference = async (token, id) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "delete",
    url: `/mealplanpreference/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const createProduct = async (token, data) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "post",
    url: "/product",
    headers: { Authorization: `Bearer ${token}` },
    data,
  };
  return await axiosInstance(options);
};

export const deleteProduct = async (token, id) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "delete",
    url: "/product/" + id,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const getProduct = async (token, id) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: "/product/" + id,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const updateProduct = async (token, data) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "patch",
    url: "/product",
    headers: { Authorization: `Bearer ${token}` },
    data,
  };
  return await axiosInstance(options);
};

export const searchMealPreferences = async (
  token,
  limit = 10,
  offset = 0,
  searchText,
  mealId
) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: "/mealpreference",
    headers: { Authorization: `Bearer ${token}` },
    params: {
      limit,
      offset,
      searchText,
      mealId,
    },
  };
  return await axiosInstance(options);
};

export const searchProducts = async (
  token,
  limit = 10,
  offset = 0,
  searchText,
  ingredientId
) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: "/product",
    headers: { Authorization: `Bearer ${token}` },
    params: {
      limit,
      offset,
      searchText,
      ingredientId,
    },
  };
  return await axiosInstance(options);
};

export const searchMealPlanPreferences = async (
  token,
  limit = 10,
  offset = 0,
  searchText,
  mealPlanId
) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: "/mealplanpreference",
    headers: { Authorization: `Bearer ${token}` },
    params: {
      limit,
      offset,
      searchText,
      mealPlanId,
    },
  };
  return await axiosInstance(options);
};

export const getMealPreferenceNutrition = async (
  token,
  mealPrefId,
  products = []
) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: `/mealpreference/${mealPrefId}/nutrition/${products.join(",")}`,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const getMealPlanPreferenceShoppingList = async (
  token,
  mealPlanPrefId
) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "patch",
    url: `/mealplanpreference/${mealPlanPrefId}/shoppinglist`,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const getMealPlanPreferenceNutrition = async (token, mealPlanPrefId) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: `/mealplanpreference/${mealPlanPrefId}/nutrition`,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const getMealPreference = async (token, mealPrefId) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: `/mealpreference/${mealPrefId}`,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const deletePlannedMealPreference = async (
  token,
  mealPlanPrefId,
  plannedMealPrefId
) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "delete",
    url: `plannedmealpreference/${plannedMealPrefId}/mealplanpreference/${mealPlanPrefId}`,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const getInventory = async (token) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: `/inventory`,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const createInventory = async (
  token,
  data = {
    bundlepreferences: [],
  }
) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "post",
    url: "/inventory",
    headers: { Authorization: `Bearer ${token}` },
    data,
  };
  return await axiosInstance(options);
};

export const getUserPreferences = async (token) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: `/user/preferences`,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const setUserPreferences = async (token, data) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "patch",
    url: `/user/preferences`,
    headers: { Authorization: `Bearer ${token}` },
    data,
  };
  return await axiosInstance(options);
};

export const clearShoppingList = async (token) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "delete",
    url: `/shoppinglist/clear`,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};

export const createUser = async (token, data) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "post",
    url: `/user`,
    headers: { Authorization: `Bearer ${token}` },
    data,
  };
  return await axiosInstance(options);
};

export const getUser = async (token) => {
  const options = {
    baseURL: OTTER_API_BASE_URL,
    method: "get",
    url: `/user`,
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axiosInstance(options);
};
