import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getIngredient } from "../../../../api/OtterApi";
import SearchableDropdown from "../../../../components/SearchableDropdown";
import { searchProducts } from "../../../../api/OtterApi";

const ProductMapperItem = ({
    ingredientId,
    productMapping,
    setProductMapping,
}) => {
    const { getAccessTokenSilently } = useAuth0();
    const [ingredient, setIngredient] = useState();
    const [products, setProducts] = useState();
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [dropdownSelected, setDropdownSelected] = useState({});

    useEffect(() => {}, [productMapping]);

    useEffect(() => {
        const instantiateIngredient = async () => {
            const token = await getAccessTokenSilently();
            const response = await getIngredient(token, ingredientId);
            if (response.status === 200) {
                setIngredient(response.data.ingredient);
            } else {
                console.error(response.data.error);
            }
        };
        instantiateIngredient();
    }, [getAccessTokenSilently, ingredientId]);

    useEffect(() => {
        const instantiateProducts = async () => {
            const token = await getAccessTokenSilently();
            const response = await searchProducts(
                token,
                10,
                0,
                "",
                ingredientId
            );
            if (response.status === 200) {
                setProducts(response.data.products);
            } else {
                console.error(response.data.error);
            }
        };
        instantiateProducts();
    }, [getAccessTokenSilently, ingredientId]);

    useEffect(() => {
        if (products) {
            const initialProduct = productMapping[ingredientId];
            if (initialProduct) {
                setDropdownSelected({
                    value: initialProduct,
                    label: initialProduct.name,
                });
            }
        }
    }, [ingredientId, productMapping, products]);

    useEffect(() => {
        if (dropdownSelected?.label === "None") {
            const productMappingCopy = { ...productMapping };
            productMappingCopy[ingredientId] = null;
            setProductMapping(productMappingCopy);
        } else if (
            dropdownSelected?.value &&
            productMapping[ingredientId] !== dropdownSelected?.value
        ) {
            const productMappingCopy = { ...productMapping };
            productMappingCopy[dropdownSelected.value.ingredient_id] =
                dropdownSelected.value;
            setProductMapping(productMappingCopy);
        }
    }, [dropdownSelected]);

    useEffect(() => {
        if (products && products.length > 0) {
            let options = products.map((product) => {
                const option = {
                    value: product,
                    label: product.name,
                };
                return option;
            });
            options = [...options, { value: null, label: "None" }];
            setDropdownOptions(options);
        }
    }, [products]);

    return (
        <div>
            <SearchableDropdown
                options={dropdownOptions}
                selected={dropdownSelected}
                onSelectedChange={setDropdownSelected}
                label={ingredient?.name}
            />
        </div>
    );
};

export default ProductMapperItem;
