import React from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import ResponsiveReactGridLayout, { WidthProvider } from "react-grid-layout";
import NextMealWidget from "./widgets/NextMealWidget";
import { isMobile } from "react-device-detect";

const WidgetDashboardPage = () => {
  const layout = [
    {
      i: "next-meal-widget",
      x: 0,
      y: 0,
      w: isMobile ? 1 : 2,
      h: 3,
      minW: 1,
      // maxW: 9,
      minH: 3,
      // maxH: 5,
    },
  ];

  const GridLayout = WidthProvider(ResponsiveReactGridLayout);

  const widgetContainerStyle = {
    width: "100%",
    height: "100vh",
    borderRadius: "8px",
    overflow: "hidden", // This prevents mobile from resizing when dragged even slightly
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          width: isMobile ? "100%" : "100%",
          flexDirection: "column",
        }}
      >
        <div style={{ marginTop: "15px", marginBottom: "15px" }}>
          <h1>Your Dashboard</h1>
        </div>
        <div style={widgetContainerStyle}>
          <GridLayout
            isDraggable={!isMobile} // Disable dragging on mobile
            className="layout"
            layout={layout}
            cols={isMobile ? 1 : 2}
            rows={2}
            maxRows={2}
            rowHeight={30}
            width={isMobile ? "" : 800}
            containerPadding={[20, 20]}
          >
            <div key="next-meal-widget">
              <NextMealWidget />
            </div>
          </GridLayout>
        </div>
      </div>
    </div>
  );
};

export default WidgetDashboardPage;
