import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./private-route";
import "./App.css";
import packageJson from "../package.json";

import Nav from "./Nav";
import LandingPage from "./pages/landing/LandingPage";

// MealPlan
import MealPlanPage from "./pages/mealplan/MealPlanPage";
import MealPlanDetailPage from "./pages/mealplan/detail/MealPlanDetailPage";
import MealPlanCreatePage from "./pages/mealplan/create/MealPlanCreatePage";
import ShoppingListPage from "./pages/shoppinglist/ShoppingListPage";

// Meal
import MealPage from "./pages/meal/MealPage";
import MealDetailPage from "./pages/meal/MealDetailPage";
import MealCreatePage from "./pages/meal/MealCreatePage";

// Ingredient
import IngredientPage from "./pages/ingredient/IngredientPage";
import IngredientDetailPage from "./pages/ingredient/IngredientDetailPage";
import IngredientCreatePage from "./pages/ingredient/IngredientCreatePage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import { useAuth0 } from "@auth0/auth0-react";
import CreateProductPage from "./pages/product/CreateProductPage";
import ProductPage from "./pages/product/ProductPage";
import ProductDetailPage from "./pages/product/ProductDetailPage";
import CreateMealPreferencePage from "./pages/meal/mealpreference/CreateMealPreferencePage";
import CreateMealPlanPreferencePage from "./pages/mealplan/mealplanpreference/CreateMealPlanPreferencePage";
import UserPreferencesPage from "./pages/user/UserPreferencesPage";
import UserSignUpPage from "./pages/user/UserSignUpPage";
import { NotificationProvider } from "./contexts/NotificationContext";
import NotificationCenter from "./components/notifications/NotificationCenter";
import WidgetDashboardPage from "./pages/dashboard/WidgetDashboardPage";

const App = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Router>
      <div className="App">
        <NotificationProvider>
          <Nav />
          <NotificationCenter />
          {isAuthenticated ? (
            <Switch>
              <Route path="/" exact component={LandingPage} />
              <PrivateRoute
                path="/dashboard"
                exact
                component={WidgetDashboardPage}
              />
              <PrivateRoute path="/mealplan" exact component={MealPlanPage} />
              <PrivateRoute
                path="/mealplan/create"
                exact
                component={MealPlanCreatePage}
              />
              <PrivateRoute
                path="/mealplan/:id"
                exact
                component={MealPlanDetailPage}
              />
              <PrivateRoute
                path="/mealplan/:id/preference/create"
                exact
                component={CreateMealPlanPreferencePage}
              />
              <PrivateRoute
                path="/mealplan/:id/preference/:prefId/edit"
                exact
                component={CreateMealPlanPreferencePage}
              />
              <PrivateRoute
                path="/mealplan/:id/preference/:prefId/shoppinglist"
                component={ShoppingListPage}
              />
              <PrivateRoute path="/meal" exact component={MealPage} />
              <PrivateRoute
                path="/meal/create"
                exact
                component={MealCreatePage}
              />
              <PrivateRoute path="/meal/:id" exact component={MealDetailPage} />
              <PrivateRoute
                path="/meal/edit/:id"
                exact
                component={MealCreatePage}
              />
              <PrivateRoute
                path="/meal/:id/preference/create"
                exact
                component={CreateMealPreferencePage}
              />
              <PrivateRoute
                path="/meal/:id/preference/:prefId/edit"
                exact
                component={CreateMealPreferencePage}
              />
              <PrivateRoute
                path="/ingredient"
                exact
                component={IngredientPage}
              />
              <PrivateRoute
                path="/ingredient/create"
                exact
                component={IngredientCreatePage}
              />
              <PrivateRoute
                path="/ingredient/:id"
                exact
                component={IngredientDetailPage}
              />
              <PrivateRoute
                path="/ingredient/edit/:id"
                exact
                component={IngredientCreatePage}
              />
              <PrivateRoute path="/product" exact component={ProductPage} />
              <PrivateRoute
                path="/product/create"
                exact
                component={CreateProductPage}
              />
              <PrivateRoute
                path="/product/:id"
                exact
                component={ProductDetailPage}
              />
              <PrivateRoute
                path="/product/edit/:id"
                exact
                component={CreateProductPage}
              />
              <PrivateRoute
                path="/user/preferences"
                exact
                component={UserPreferencesPage}
              />
              <PrivateRoute
                path="/user/signup"
                exact
                component={UserSignUpPage}
              />
            </Switch>
          ) : (
            <LandingPage />
          )}
        </NotificationProvider>
        <div className="version">{packageJson.version}</div>
      </div>
    </Router>
  );
};

export default App;
